
import { Component, Prop, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { sleep } from '@/utils/helpers';
import { DateTime } from 'luxon';

@Component({
  components: {
    Multiselect,
  },
})
export default class TasqWellStatus extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  comment = '';

  startDate = '';

  endDate = '';

  reason = '';

  isSavingData = false;

 waitingOnOptions: any = []


  async setWaitingOnOptions() {
  if(!metaDataModule.requiredDatalist.length){
    await metaDataModule.getDatalist();
  }

   const requiredDataList = metaDataModule.requiredDatalist;
   const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
   // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
   if (formCategoriesItem) {
     const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
     if (formCategoriesPayload && formCategoriesPayload.length && formCategoriesPayload[0].ListItems) {
       const waitingOnOptions = formCategoriesPayload[0].ListItems.filter((field) => field.Active).map((item) => item.Name);
       // waitingOnOptions =
       this.waitingOnOptions = waitingOnOptions.sort();
     }
   }
 }

  get disabledDates() {
    return [
      (date) => {
        const startDate = new Date(this.startDate);
        const endDate = DateTime.fromISO(date.toISOString());
        let daysDiff: any = DateTime.local().diff(endDate, 'days').toObject()?.days?.toFixed();
        startDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return date <= startDate || daysDiff > 3;
      },
    ];
  }

  get isValidForm() {
    if (!this.startDate) {
      return false;
    }
    if (!this.reason) {
      return false;
    }
    if (this.startDate && this.endDate < this.startDate) {
      return false;
    }
    return true;
  }

  async created() {
    if (this.tasq.snoozed && !this.tasq.isSnoozedForToday) {
      this.comment = this.tasq.waitUntilReason.split('-Remarks:')[1] || '';
      this.startDate = this.tasq.snoozed.WaitStartDate;
      this.endDate = this.tasq.snoozed.UntilDate;
      this.reason = this.tasq.waitUntilReason.split('-Remarks:')[0];
    }
    await this.setWaitingOnOptions()
  }

  async saveWellStatus() {
    this.isSavingData = true;
    await sleep(100);
    try {
      const action = {
        id: this.tasq.engineerType !== 'Producing' ? this.tasq.id : '',
        wellName: this.tasq.wellName,
        comment: this.comment,
        startDate: this.startDate,
        reason: this.reason,
        endDate: this.endDate,
        level: this.tasq.level
      };

      const newTasq = {
        ...this.tasq,
        waitingOnEndDate: this.endDate,
        waitingOnStartDate: this.startDate,
        waitUntilReason: `${this.reason} -Remarks: ${this.comment}`,
        snoozed: {
          Status: true,
          UntilDate: this.endDate,
          WaitStartDate: this.startDate,
          WaitUntilReason: `${this.reason} -Remarks: ${this.comment}`,
        },
      };


      if (this.tasq.engineerType !== 'Producing' || this.tasq.level === 'PAD') {
        tasqsListModule.updateTasqDetails(newTasq);
        tasqsListModule.setActiveTasqExplicit(newTasq)

      } else {
        tasqsListModule.updateTasqDetails(newTasq);
      }


      tasqsListModule.setIsLoadingTasqs(true);
      this.$nextTick(async () => {
        tasqsListModule.setIsLoadingTasqs(false);
      });

      await sleep(100);
      await tasqActionsModule.mutateWellStatus(action);
      await sleep(100);
      // if (this.tasq.engineerType !== 'Producing') {
      //   await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
      // }

      this.$tasqAlert({
        title: 'Success',
        message: 'Update well status saved! ',
        type: 'success',
      });
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Update well status failed! ',
        type: 'error',
      });
      console.log(error);
    }

    this.isSavingData = false;
  }
}
